:root {
    --main-bg-color: #ebe5c3;
    --very-dark: #222222;
    --dark: #323232;
    --light: #b0b0b0;
    --lighter: #d0d0d0;
    --lightest: #f0f0f0;
    --attention: #a00000;
    --bordered: 2px solid #5e5e5e;
    --success: rgba(0,120,0,.8);
}
.App {
    position: relative;
    overflow: hidden;
}

body {
  background-color: var(--main-bg-color);
}

.App-header {
    position: relative;
    text-align: center;
    min-height: 5vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}
.App-header h2 {
    flex-grow: 95;
}
.App-header .floatNav {
    flex-grow: 5;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
}
.content .navigation {
    margin: 20px 0;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.centered {
    text-align: center;
}
.notTooBig {
    max-width: 600px;
}

.App-header a {
  padding: 0 20px;
}

.App-link {
  color: #61dafb;
}

.blurb {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.blurb .about {
    max-width: 300px;
    margin: 0 10px;
}

.blurb .carpenter {
    width: 100px;
    text-align: center;
    margin: 0 10px;
}

.catalog {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.catalog .item {
  width: 210px;
  padding: 10px;
}
.catalog .item img {
    width: 210px;
}
.clickable {
    text-decoration: underline;
    color: rgb(0,0,238);
}

.circle {
    border-radius: 50%;
    background-color: var(--attention);
    color: var(--lighter);
    line-height: 21px;
    height: 21px;
    width: 21px;
    display: inline-block;
    font-weight: bold;
}
.App-header .circle {
    line-height: calc(10px + 2vmin);
    height: calc(10px + 2vmin);
    width: calc(10px + 2vmin);
}

button {
    cursor: pointer;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-box-sizing: border-box; /* For legacy WebKit based browsers */
    -moz-box-sizing: border-box; /* For legacy (Firefox <29) Gecko based browsers */
}

.addToCart {
    background-color: var(--light);
    border: var(--bordered);
    color: var(--dark);
    cursor: pointer;
}
.addToCart:hover {
    background-color: var(--lighter);
}
.addToCart:disabled {
    border: 2px solid var(--light);
    background-color: var(--lighter);
}
.emailUs {
    margin: 10px 0;
}

textarea, input {
    width: calc(225px + 10vw);
    padding: 8px;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-box-sizing: border-box; /* For legacy WebKit based browsers */
    -moz-box-sizing: border-box; /* For legacy (Firefox <29) Gecko based browsers */
    box-sizing: border-box;
    box-shadow: var(--very-dark);
    border-radius: 5px;
    outline: none;
    font-size: large;
}
.withAction input {
    width: calc(200px + 10vw);
}
.inlineBlock {
    display: inline-block;
}
.withPadding {
    padding: 10px;
}
.withPaddingRight {
    padding-right: 10px;
}
.withPaddingBottom {
    padding-bottom: 10px;
}
input:focus, textarea:focus {
    background   : rgba(0,0,0,.1);
    border-radius: 5px;
    outline      : none;
}
.tableCell input.number {
    width: 30px;
    padding: 0;
}
.errorMessage {
  color: var(--attention);
}
.video {
    margin-bottom: 15px;
}
.payNav {
    margin-top: 15px;
    line-height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: large;
}
.payNav button {
    font-size: large;
}
.payNav a {
    white-space: nowrap;
}
.payNav a.checkout {
    text-decoration: none;
    padding: 5px;
    border: var(--bordered);
    background-color: var(--very-dark);
    color: #f0f0f0;
    font-size: large;
}
.payNav a.checkout:hover {
    background-color: var(--dark);
}
.payNav .back {
    flex-basis: 40%;
}

/* for input type=checkbox */
.shipping label {
    cursor: pointer;
}
.shipping input {
    margin-top: 2px;
    vertical-align: middle;
}
.shippingCost {
    font-weight: bold;
    font-size: large;
}

button.link {
    padding: 0;
    border: none;
    background: inherit;
    text-decoration: underline;
    cursor: pointer;
    font-size: large;
    font-weight: bold;
}

.floatNav a.button,
.navigation a.button {
    text-decoration: none;
    padding: 5px;
    margin: 5px;
    border: var(--bordered);
    background-color: var(--light);
    color: var(--very-dark);
    white-space: nowrap;
}
.floatNav a.button:hover,
.navigation a:hover {
  background-color: var(--lighter);
}
button.big,
button.pay {
    height: 45px;
    background-color: var(--very-dark);
    color: var(--lightest);
}
button.big:hover,
button.pay:hover {
    background-color: var(--dark);
}
button.big {
    height: 36px;
}
div.spinnerModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(34, 34, 34, .2);
}
.spinnerModal svg {
    position: absolute;
    top: 40vh;
    left: 40vw;
}

.floatNav a,
.navigation a {
    margin: 5px;
}
.orderId {
  font-weight: bold;
  font-size: large;
}
.price {
    font-weight: bold;
    margin-right: 15px;
}
.lowInventory {
    color: var(--attention);
}

#slider {
    width: 270px;
    height: 100px;
    background: var(--success);
    color: var(--lightest);
    border: var(--bordered);
    text-align: center;
    z-index: 100;
}

.slideIn {
    position: fixed;
    top: -200px;
    left: calc(50% - 135px);
    -webkit-animation: slideOut 0.5s forwards;
    -webkit-animation-delay: 0s;
    animation: slideIn 0.5s forwards;
    animation-delay: 0s;
}

@-webkit-keyframes slideIn {
    100% { top: 8vh; }
}

@keyframes slideIn {
    100% { top: 8vh; }
}

.slideOut {
    position: fixed;
    top: 8vh;
    left: calc(50% - 135px);
    -webkit-animation: slideOut 0.5s forwards;
    -webkit-animation-delay: 0s;
    animation: slideOut 0.5s forwards;
    animation-delay: 0s;
}

@-webkit-keyframes slideOut {
    100% { top: -200px; }
}

@keyframes slideOut {
    100% { top: -200px; }
}

.hidden {
    display: none;
}

.description {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 300px;
    text-align: left;
    padding: 5px;
}
.description .name {
    font-weight: bold;
    font-size: large;
}
.priceRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    line-height: 36px;
    font-weight: bold;
    font-size: large;
}
.table {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
}
.checkout table {
    max-width: 800px;
}
.tableRow {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
}
.table.admin {
    max-width: 1024px;
}
.admin .tableRow {
    margin: 10px 0;
    cursor: pointer;
}
.adminControls {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    line-height: 36px;
    align-items: center;
}
.adminControls .centerVertically {
    display: flex;
    align-items: center;
}
.addStockings input[type=checkbox],
.adminControls input[type=checkbox] {
    width: 20px;
    -webkit-appearance: checkbox;
    -moz-appearance: checkbox;
    display: inline;
}
.addStockings {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 5px;
    cursor: pointer;
}

.addStockings input[type=checkbox] {
    margin-top: 2px;
    margin-left: 8px;
    vertical-align: middle;
}

.nameAndInventory {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}
.checkout .tableRow {
    flex-wrap: wrap;
}
.table.cart .tableRow {
    padding: 5px 0;
    border-bottom: 2px solid var(--dark);
}
.borderTop {
    border-top: 2px solid var(--dark);
}
.table.cart i {
    padding: 0 5px;
    cursor: pointer;
}
.tableCell {
    flex-grow: 10;
    flex-basis: 0;
}
.checkout .tableCell.label {
    font-weight: bold;
    min-width: 100px;
    padding-right: 10px;
}
.bold {
    font-weight: bold;
    font-size: large;
}
.tableCell.g3 {
    flex-grow: 30;
}
.tableCell.g4 {
    flex-grow: 40;
}
.tableCell.g2 {
    flex-grow: 20;
}
.tableCell.tiny {
    flex-grow: 2;
}
.tableCell.noWrap {
    white-space: nowrap;
}
.deliveryEstimate {
    padding: 5px 0;
    font-weight: bold;
}
.bordered {
    border: var(--bordered);
}
.alignRight {
    text-align: right;
}

@media all and (max-width: 600px) {
    .hideIfSmall {
        display:none;
        width:0;
        height:0;
        opacity:0;
        visibility: collapse;
    }

    .g3.shrinkIfSmall {
        flex-grow: 20;
    }
    .g4.shrinkIfSmall {
        flex-grow: 30;
    }
}
@media all and (min-width: 600px) {
    .hideIfBig {
        display:none;
        width:0;
        height:0;
        opacity:0;
        visibility: collapse;
    }
}

@media all and (min-width: 875px) {
    .floatNav {
        position: absolute;
        right: 50px;
        top: 25%;
    }
}

